var SEARCH_PATHS = {
  ASK_A_QUESTION: 'activeModal=ASK_A_QUESTION',
  CALCULATOR: 'activeModal=NAPKIN_MODAL',
  COVID: 'activeModal=COVID',
  HOW_IT_WORKS: 'activeModal=HOW_IT_WORKS',
  SMOOTH_PAYMENTS: 'activeModal=SMOOTH_PAYMENTS',
  PAYMENT_METHODS: 'activeModal=PAYMENT_METHODS',
  HELP_BRIDGE: 'activeModal=HELP_BRIDGE'
};
var BASE_PATHS = {
  HOME: '/homes',
  ACCOUNTS: '/accounts',
  HOMEOWNER: '/homeowners',
  HOMEOWNER_ONBOARDING: '/homeowner/onboard',
  HOMEOWNER_SETUP_FLOW: '/accounts/portfolio/properties',
  POST_INSPECTION_FLOW: '/post-inspection/homeowners',
  AGREEMENT_FLOW: '/agreement/homeowners',
  LEASE_SIGNING: '/lease-signing',
  AUTH: '/auth'
};
var BELONG_X_STORES = {
  APP_STORE: 'https://apps.apple.com/us/app/belong/id6450795629',
  GOOGLE_PLAY: 'https://play.google.com/store/apps/details?id=com.belong.belongx'
};
var PATHS = {
  HOME_PAGE: '/',
  // Homeowner flows
  SETUP_FLOW: "".concat(BASE_PATHS.HOMEOWNER_SETUP_FLOW, "/:propertyId?/setup-flow/:unitId?/steps/:flowName?/:step?/:stepId?"),
  SETUP_FLOW_SUCCESS: "".concat(BASE_PATHS.HOMEOWNER_SETUP_FLOW, "/:propertyId?/setup-flow/success"),
  POST_INSPECTION_FLOW: "".concat(BASE_PATHS.POST_INSPECTION_FLOW, "/:group?/:step?/:stepId?"),
  AGREEMENT_FLOW: "".concat(BASE_PATHS.AGREEMENT_FLOW, "/:step?/:flowId?/:stepId?"),
  SMOOTH_PAYMENT: '/smooth-payments/:homeId',
  SMOOTH_PAYMENT_SUCCESS: '/smooth-payments/:homeId/success',
  LANDLORD_INSURANCE_FLOW: '/accounts/portfolio/landlord-insurance',
  HO_BETTER_RENEWALS_FLOW: '/accounts/portfolio/properties/renewal-offer',
  RES_BETTER_RENEWALS_FLOW: '/accounts/portfolio/properties/sign-renewal-offer',
  HO_PRE_MOVE_OUT_FLOW: '/accounts/portfolio/pre-move-out',
  // end homeowner flows.
  LISTING_PAGE: "".concat(BASE_PATHS.HOME, "/:cityAndState(\\D*)?"),
  HOUSE_PAGE_HOME_UNIQUE_ID: "".concat(BASE_PATHS.HOME, "/:homeId"),
  HOUSE_PAGE: "".concat(BASE_PATHS.HOME, "/:city/:homeId"),
  HOUSE_PAGE_SECTION: "".concat(BASE_PATHS.HOME, "/:city/:homeId/:sectionId?"),
  HOUSE_PREVIEW_PAGE: "/preview".concat(BASE_PATHS.HOME, "/:homeId"),
  HOMEOWNER_ONBOARDING: '/homeowner/onboard/:group?/:step?/:stepId?',
  HOMEOWNER_ONBOARDING_PROPERTY_ADDRESS: '/homeowner/onboard/property-address',
  // Auth
  AUTH_LOGIN: "".concat(BASE_PATHS.AUTH, "/login"),
  AUTH_SET_PASSWORD: "".concat(BASE_PATHS.AUTH, "/set-password"),
  // Accounts
  ACCOUNTS_REFFERAL: "".concat(BASE_PATHS.ACCOUNTS, "/referral"),
  ACCOUNTS_PAYMENTS: "".concat(BASE_PATHS.ACCOUNTS, "/payments"),
  ACCOUNTS_PROFILE: "".concat(BASE_PATHS.ACCOUNTS, "/profile"),
  ACCOUNTS_REFERRAL_PAYMENTS: "".concat(BASE_PATHS.ACCOUNTS, "/referrer/payments"),
  ACCOUNT_BILLS: "".concat(BASE_PATHS.ACCOUNTS, "/bills"),
  ACCOUNT_INBOX: "".concat(BASE_PATHS.ACCOUNTS, "/member/inbox"),
  // Account homeowners paths
  HOMEOWNERS_ACCOUNT_PROPERTIES: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/properties"),
  HOMEOWNER_ACCOUNT_EARNINGS: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/earnings"),
  HOMEOWNER_ACCOUNT_WEALTH: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/wealth"),
  HOMEOWNER_ACCOUNT_MAINTENANCE: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/maintenance"),
  HOMEOWNER_ACCOUNT_RECURRING_MAINTENANCE: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/maintenance/recurrence"),
  HOMEOWNER_ACCOUNT_DOCUMENTS: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/documents"),
  HOMEOWNER_ACCOUNT_INBOX: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/inbox"),
  HOMEOWNER_ACCOUNT_SELL: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/sell"),
  HOMEOWNER_ACCOUNT_WEALTH_ANALYSIS_FLOW: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/wealth-analysis"),
  HOMEOWNER_ACCOUNT_INSURANCE_SUMMARY: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/landlord-insurance/summary"),
  HOMEOWNER_ACCOUNT_LISTING_PERFORMANCE: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/listing-performance"),
  HOMEOWNER_REDIRECTS: "".concat(BASE_PATHS.ACCOUNTS, "/homeowners"),
  HOMEOWNER_COMPARE: '/homeowners/compare',
  // Account residents paths
  RESIDENTS_ACCOUNT_PROPERTIES: "".concat(BASE_PATHS.ACCOUNTS, "/home/properties"),
  RESIDENTS_ACCOUNT_PAYMENTS: "".concat(BASE_PATHS.ACCOUNTS, "/home/payments"),
  RESIDENTS_ACCOUNT_MAINTENANCE: "".concat(BASE_PATHS.ACCOUNTS, "/home/maintenance"),
  RESIDENTS_ACCOUNT_RECURRING_MAINTENANCE: "".concat(BASE_PATHS.ACCOUNTS, "/home/maintenance/recurrence"),
  RESIDENTS_ACCOUNT_DOCUMENTS: "".concat(BASE_PATHS.ACCOUNTS, "/documents"),
  RESIDENTS_ACCOUNT_TOURS_AND_APPS: "".concat(BASE_PATHS.ACCOUNTS, "/tours-and-apps"),
  RESIDENTS_ACCOUNT_FAVORITES: "".concat(BASE_PATHS.ACCOUNTS, "/favorites"),
  RESIDENTS_ACCOUNT_INBOX: "".concat(BASE_PATHS.ACCOUNTS, "/inbox"),
  RESIDENTS_REDIRECTS: "".concat(BASE_PATHS.ACCOUNTS, "/residents"),
  RESIDENTS_POST_MOVE_OUT_FLOW: "".concat(BASE_PATHS.ACCOUNTS, "/home/post-move-out"),
  // belong-web specific account paths
  PORTFOLIO_MAINTENANCE_RECURRENCE_SUBTASK: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/maintenance/recurrence/:homeUniqueId/:recurrenceType/:subtaskId"),
  PORTFOLIO_MAINTENANCE_RECURRENCE: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/maintenance/recurrence/:homeUniqueId/:recurrenceType"),
  PORTFOLIO_MAINTENANCE: "".concat(BASE_PATHS.ACCOUNTS, "/portfolio/maintenance/:taskId"),
  HOME_MAINTENANCE_RECURRENCE_SUBTASK: "".concat(BASE_PATHS.ACCOUNTS, "/home/maintenance/recurrence/:homeUniqueId/:recurrenceType/:subtaskId"),
  HOME_MAINTENANCE_RECURRENCE: "".concat(BASE_PATHS.ACCOUNTS, "/home/maintenance/recurrence/:homeUniqueId/:recurrenceType"),
  HOME_MAINTENANCE: "".concat(BASE_PATHS.ACCOUNTS, "/home/maintenance/:taskId"),
  ACCOUNTS: "".concat(BASE_PATHS.ACCOUNTS, "/:section?/:subSection?/:propertyId?/:unitId?"),
  //
  HOMEOWNER_PRO_SERVICE_CREATE: '/homeowner/pro-services/:proService',
  HOMEOWNER_PRO_SERVICE_EDIT: '/homeowner/pro-services/:proService/:taskId',
  RESIDENT_APPLICATION: '/resident/:houseId/applications/',
  RESIDENT_ONBOARDING: '/resident/onboarding',
  HOMEOWNER_LANDING: '/homeowners/:regionSlug(bay-area|los-angeles|orange-county|san-diego|seattle|miami)?',
  HOMEOWNER_CREATE_TASK: '/homeowner/pro-services/create',
  RESIDENT_CREATE_TASK: '/resident/pro-services/create',
  RESIDENT_PRO_SERVICE_CREATE: '/resident/pro-services/:proService',
  RESIDENT_PRO_SERVICE_EDIT: '/resident/pro-services/:proService/:taskId',
  RECESSION_LANDING_PAGE: '/homeowners/renting-in-a-recession',
  HOMEOWNER_CITY_LANDING: '/homeowners/:cityId',
  OCCUPANCY_STANDALONE: '/homeowners/availability-check-in/:homeId',
  RESIDENT_LANDING: '/residents',
  COAPPLICANT: '/coapplicant/:applicationId',
  ABOUT_US: '/about-us',
  FAQ: '/faq',
  SITEMAP: '/sitemap',
  SITEMAP_HOMES_TO_LOVE: '/sitemap/homes-to-love',
  HOW_IT_WORKS: '/how-it-works',
  TERMS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  CAREERS: '/careers',
  COVID: '/renting-during-recession',
  PRO_SERVICES: '/pro-services',
  LEASE_SIGNING_FLOW: "".concat(BASE_PATHS.LEASE_SIGNING, "/:homeId"),
  FILE_DOWNLOAD: '/file/download/:fileType/:ownerId',
  BLOG: '/blog',
  ABOUT_AUTHOR: '/blog/author/:authorId',
  LANDING_PAGE: '/lp/:uid',
  BLOG_POST: '/blog/:uid',
  CONTENT_PREVIEW: '/content-preview',
  REFERRAL_LANDING: '/referral',
  AFFILIATE: '/affiliates',
  // BELONGX LANDING
  BELONG_X: '/homeowners/x',
  BELONG_X_RENTERS: '/residents/x',
  BELONG_COMPARE: '/homeowners/compare',
  // W9 FLow
  W9_FLOW: '/accounts/w9-flow/:flowId',
  // Help Bridge,
  HELP: '/help',
  // Support Page,
  SUPPORT: '/support',
  // Belong X Invite Flow
  JOIN_SUCCESS: '/members/join/success',
  // Belong X Onboarding
  BELONG_X_ONBOARDING: '/homeowners/x/onboarding',
  BELONG_X_ONBOARDING_INFO: '/homeowners/x/onboarding/info',
  BELONG_X_ONBOARDING_LEASE: '/homeowners/x/onboarding/lease',
  BELONG_X_ONBOARDING_LEASE_ESTIMATE: '/homeowners/x/onboarding/estimate',
  BELONG_X_ONBOARDING_PLACEMENTS: '/homeowners/x/onboarding/placements',
  BELONG_X_ONBOARDING_LEASING: '/homeowners/x/onboarding/leasing',
  BELONG_X_DOWNLOAD: '/homeowners/x/download',
  BELONG_X_RENT_GUARANTEE: '/homeowners/x/onboarding/rent-guarantee',
  // Belong QUALIFICATION FLOW
  BELONG_QUALIFICATION_FLOW: '/homeowners/qualification/:flowId/property/:stepName/:stepId',
  BELONG_QUALIFICATION_FLOW_SUCCESS: '/homeowners/qualification/:flowId/success',
  // Belong TOURS AND APPLICATIONS FLOW
  BELONG_TOURS_AND_APPLICATIONS_FLOW: '/homeowners/tours-and-applications/:unitId/:flowId/property/:stepName/:stepId',
  INSURANCE: {
    COVERAGE: '/accounts/portfolio/landlord-insurance/coverage-selection'
  },
  MEETINGS: '/meetings/:meetingType',
  // Belong Leasing Landing
  LEASING_LANDING: '/homeowners/leasing',
  // Homeowner Rent Estimate Flow
  RENT_ESTIMATE: '/rent-estimate',
  RENT_ESTIMATE_ACCOUNT: '/rent-estimate/account',
  RENT_ESTIMATE_HOME: '/rent-estimate/home',
  RENT_ESTIMATE_EXPECTATION: '/rent-estimate/expectation',
  RENT_ESTIMATE_CONDITION: '/rent-estimate/condition',
  RENT_ESTIMATE_PETS: '/rent-estimate/pets',
  RENT_ESTIMATE_PRIMARY_MATERIAL: '/rent-estimate/primary-material',
  RENT_ESTIMATE_FEATURES: '/rent-estimate/features',
  RENT_ESTIMATE_CALCULATION: '/rent-estimate/calculation',
  RENT_ESTIMATE_REPORT: '/rent-estimate/report',
  RENT_ESTIMATE_SUCCESS: '/rent-estimate/report/success',
  RENT_ESTIMATE_OUT_OF_COVERAGE: '/rent-estimate/report/out-of-coverage',
  RENT_ESTIMATE_SUCCESS_INCOMPLETE_REPORT: '/rent-estimate/report/incomplete-report'
};

export { BASE_PATHS, BELONG_X_STORES, PATHS, SEARCH_PATHS };
